<template>
  <div>
    <kn-form-subtitle :title="`${actionTitle} penalización`" />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row>
        <v-col
          class="pb-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4">
          <v-subheader>Nombre:</v-subheader>
        </v-col>
        <v-col 
          class="pb-0">
          <v-text-field
            v-model="penalty.nombre"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Descripción:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-text-field
            v-model="penalty.descripcion"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col 
          class="py-0" 
          cols="12"
          md="4"
          lg="4"
          xl="4"
        >
          <v-subheader>Monto:</v-subheader>
        </v-col>
        <v-col class="py-0">
          <v-text-field
            v-model.number="penalty.monto"
            dense
            outlined
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <kn-form-action-buttons
        class="mb-14"
        :small="true"
        :disableAccept="invalidFields"
        @accept="save"
        @cancel="clear"
      />
    </v-form>
    <kn-form-subtitle
      title="Listado de penalizaciones"
      divider-position="bottom"
    />
    <v-list>
      <v-list-item-group>
        <template v-for="(item, index) in activePenalties">
          <v-list-item :key="item.nombre">
              <v-list-item-content>
                <v-text-field
                  v-if="toUpdate && item.id === penalty.id"
                  class="mb-0 pb-0"
                  v-model="penalty.nombre"
                  dense
                  outlined
                />
                <v-list-item-title
                  v-else
                  class="purple--text"
                  v-text="item.nombre"
                />
                <v-text-field
                  v-if="toUpdate && item.id === penalty.id"
                  v-model="penalty.descripcion"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.descripcion"
                />
                <v-text-field
                  v-if="toUpdate && item.id === penalty.id"
                  v-model.number="penalty.monto"
                  dense
                  outlined
                />
                <v-list-item-subtitle
                  v-else
                  v-text="item.monto"
                />
              </v-list-item-content>

              <v-list-item-action>
                <v-row
                  v-if="toUpdate && item.id === penalty.id"
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Cancelar"
                      @click="clearInline"
                    >
                      <v-icon color="primary">
                        mdi-cancel
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Guardar cambios"
                      @click="save"
                    >
                      <v-icon color="primary">
                        mdi-content-save-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row
                  v-else
                  no-gutters
                >
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Editar"
                      @click="edit(item)"
                    >
                      <v-icon color="primary">
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn
                      icon
                      color=primary
                      title="Eliminar"
                      @click="deleteInline(item.id)"
                    >
                      <v-icon color="primary">
                        mdi-trash-can-outline
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
          </v-list-item>

          <v-divider
            v-if="index < activePenalties.length - 1"
            :key="index"
          ></v-divider>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { api } from '@/api/axios-base'
import { validationFormMixin } from '@/mixins/validationFormMixin'
export default {
  components: {
    KnFormSubtitle: () => import('@/components/KnFormSubtitle.vue'), 
    KnFormActionButtons: () => import('@/components/KnFormActionButtons.vue') 
  },
  mixins: [validationFormMixin],
  data() {
    return {
      errors: [],
      valid: true,
      penalty: {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        monto: null,
        id_autor: null,
        id_institucion_educativa: null
      },
      penalties: [],
      toUpdate: false
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    actionTitle() {
      return this.toUpdate ? 'Actualizar' : 'Crear'
    },
    activePenalties() {
      return this.penalties.filter(penalty => penalty.estatus_sistema === true)
    },
    invalidFields() {
      return this.objectHasNulls(this.penalty)
    }
  },
  async created() {
    this.penalty.id_autor = this.userData.id
    this.penalty.id_institucion_educativa = this.institutionId
    this.penalties = await this.fetchPenaltyByEI(this.institutionId)

  },
  methods: {
    async fetchPenalties() {
      try {
        const response = await api.get('/administracion/api/penalizaciones-all/')
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener penalizaciones', error);
      }
    },
    async fetchPenaltyByEI(institutionId) {
      try {
        const response = await api.get(`/administracion/api/penalizacion-by-ie/?id_institucion=${institutionId}`)
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar obtener penalizaciones por institucion educativa', error);
      }
    },
    async createPenalty() {
      try {
        const response = await api.post('/administracion/crear-penalizacion', {
          nombre: this.penalty.nombre,
          descripcion: this.penalty.descripcion,
          monto: this.penalty.monto,
          id_autor: this.penalty.id_autor,
          id_institucion_educativa: this.penalty.id_institucion_educativa
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo crear la penalización.')
        }
      } catch (error) {
        console.error('Error al intentar crear penalizacion', error)
        this.errors.push('Error al intentar crear penalizacion')
      }
    },
    async updatePenalty() {
      try {
        const response = await api.patch('/administracion/update-penalizacion', {
          id: this.penalty.id,
          nombre: this.penalty.nombre,
          descripcion: this.penalty.descripcion,
          monto: this.penalty.monto,
          id_institucion_educativa: this.institutionId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo actualizar la penalización')
        }
      } catch (error) {
        console.error('Error al intentar actualizar penalizacion', error);
      }
    },
    // Los deletes no eliminan los registros, cambian el estatus a False
    async deletePenalty(penaltyId) {
      try {
        const response = await api.post('/administracion/delete-penalizacion', {
          id_penalizacion: penaltyId
        })
        const responseData = await response.data
        if (responseData.e) {
          this.errors.push('Ocurrió un error, no se pudo eliminar la penalización')
        }
      } catch (error) {
        console.error('Error al intentar eliminar penalizacion', error);
      }
    },
    async save() {
      if (this.toUpdate) {
        await this.updatePenalty()
        this.toUpdate = false
      } else {
        await this.createPenalty()
        this.clear()
      }
      this.penalties = await this.fetchPenalties()
      this.clear()
    },
    clear() {
      this.penalty = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        monto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    clearInline() {
      this.penalty = {
        id: 'NA', // No asignado
        nombre: null,
        descripcion: null,
        monto: null,
        id_autor: this.userData.id,
        id_institucion_educativa: this.institutionId 
      }
      this.toUpdate = false
      this.$refs.form.resetValidation()
    },
    edit(item) {
      this.penalty = {...item}
      // console.log('Item a editar:', this.penalty);
      this.toUpdate = true
    },
    async deleteInline(itemId) {
      await this.deletePenalty(itemId)
      this.penalties = await this.fetchPenalties()
      this.clearInline()
    }
  },
}
</script>

<style>

</style>